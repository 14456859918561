import {Divider} from "antd";
import clsx from "clsx";
import {LoginMode} from "@/lib/type/login-register.type";

type Props = {
    mode: LoginMode[],
    activeId: number;
    setActiveId: (activeId: number) => void;
    title: string;
}
export default function LoginMethod({mode, activeId, setActiveId, title}: Props) {
    return <div>
        <Divider><p className="text-xs text-gray-500">{title}</p></Divider>
        <div className="c-flex gap-x-10 my-4 py-8 bg-gradient-to-b from-[#fffefd] to-[#fef5e7] rounded-[5px]">
            {
                mode.map(item => (
                    <div
                        onClick={() => setActiveId(item.id)}
                        className={clsx("c-flex flex-col gap-y-2 group cursor-pointer", item.id === activeId ? "!hidden" : "!flex")}
                        key={item.id}>
                        <i className={clsx(item.icon, "ri-xl")}></i>
                        <p className="text-xs text-themeP group-hover:text-main duration-300">{item.name}</p>
                    </div>
                ))
            }
        </div>
    </div>;
}
