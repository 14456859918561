"use client";
import {AccountLoginParams} from "@/lib/type/login-register.type";
import {useEffect, useState} from "react";
import {App, Button, Checkbox, Form, Input} from "antd";
import SliderVerification from "@/components/slide-verification";
import {requestAccountLogin} from "@/lib/api/login-register";
import {useRouter, useSearchParams} from "next/navigation";
import {useUserStore} from "@/lib/store/user.store";
import ThemeLink from "@/components/link";
import {getForgotPasswordLink} from "@/lib/util/get-router";

type FieldType = Pick<AccountLoginParams, "mobile" | "password"> & {
    slideVerification: boolean;
}

export default function AccountLogin() {
    const [sliderValidate, setSliderValidate] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const {setUserInfo, remember, setRemember} = useUserStore();
    const [form] = Form.useForm<FieldType>();
    const {message} = App.useApp();
    const searchParams = useSearchParams();
    const router = useRouter();
    const onFinish = async (value: FieldType) => {
        try {
            setLoading(true);
            const res = await requestAccountLogin({
                mobile: value.mobile,
                password: value.password,
                group: "tinyShopPc"
            });
            if (res.code === 200) {
                message.success("登录成功");
                setUserInfo(res.data);
                const redirect = searchParams.get("redirect") || "/";
                router.replace(redirect);
            } else {
                message.error(res.message);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        form.setFieldsValue({slideVerification: sliderValidate});
    }, [sliderValidate]);

    return <>
        <p className="text-black font-bold my-4">账号登录</p>
        <Form
            form={form}
            name="account-login"
            onFinish={onFinish}
            wrapperCol={{span: 24}}
            style={{maxWidth: 600}}
            autoComplete="off"
        >
            <Form.Item<FieldType>
                name="mobile"
                rules={[{
                    validator: (rule, value) => {
                        const reg = /^[1][3456789][0-9]{9}$/;
                        if (!value) {
                            return Promise.reject("手机号码不能为空");
                        }
                        if (!reg.test(value)) {
                            return Promise.reject("手机号码格式错误");
                        } else {
                            return Promise.resolve();
                        }
                    }
                }]}
            >
                <Input className="!py-2 !text-[16px]" placeholder={"请输入手机号码"}/>
            </Form.Item>
            <Form.Item<FieldType>
                name="password"
                rules={[{
                    validator: (rule, value) => {
                        if (!value) return Promise.reject("密码不能为空");
                        return Promise.resolve();
                    }
                }]}
            >
                <Input.Password className="!py-2 !text-[16px]"
                                placeholder={"请输入密码"}/>
            </Form.Item>
            <Form.Item className="!mb-0 !h-[70px]">
                <SliderVerification getResult={setSliderValidate}/>
                <Form.Item<FieldType>
                    className="relative -top-8 !mb-0"
                    name="slideVerification"
                    valuePropName="checked"
                    rules={[{
                        validator: (rule, value) => {
                            if (!value) return Promise.reject("请完成人机验证");
                            else return Promise.resolve();
                        }
                    }]}
                >
                    <Checkbox/>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Form.Item>
                    <div className="b-flex">
                        <Checkbox onChange={(e) => setRemember(e.target.checked)}
                                  checked={remember}>下次自动登录</Checkbox>
                        <ThemeLink href={getForgotPasswordLink()}>
                            忘记密码
                        </ThemeLink>
                    </div>
                </Form.Item>
                <Button type="primary" size="large" block htmlType="submit" loading={loading}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    </>;
}
