"use client";
import {App, Button, Checkbox, Form, Input} from "antd";
import {useEffect, useState} from "react";
import {requestGetSmsCode, requestMobileCodeLogin} from "@/lib/api/login-register";
import SliderVerification from "@/components/slide-verification";
import ThemeLink from "@/components/link";
import {useUserStore} from "@/lib/store/user.store";
import {useRouter, useSearchParams} from "next/navigation";
import {getForgotPasswordLink} from "@/lib/util/get-router";
import {useUserInfo} from "@/hooks/useUserInfo";
import {CodeError} from "@/lib/util/util";

type FieldType = {
    mobile: string;
    code: string;
    slideVerification: boolean;
};

export default function PhoneNumberLogin() {
    const [count, setCount] = useState<number>(0);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [buttonText, setButtonText] = useState<string>("获取验证码");
    const [codeLock, setCodeLock] = useState(false);
    const [sliderValidate, setSliderValidate] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [codeErrorMsg, setCodeErrorMsg] = useState<string>("");
    const searchParams = useSearchParams();
    const router = useRouter();
    const {setRemember, remember, setUserInfo} = useUserStore();
    const [form] = Form.useForm();
    const {message} = App.useApp();
    const {getUserInfo} = useUserInfo();
    const handleGetCode = async () => {
        try {
            // 判断手机号码是否正确
            await form.validateFields(["mobile"]);
            // 如果正在倒计时，不做任何操作
            if (count > 0 || codeLock) return;
            setCodeLock(true);
            // 发送验证码
            const res = await requestGetSmsCode({
                mobile: form.getFieldValue("mobile"),
                usage: "login"
            });
            if (res.code === 200) {
                // 开始倒计时
                message.success("验证码已发送，请注意查收");
                setCount(60);
            } else {
                message.error(res.message);
            }
        } catch (e) {
            return;
        } finally {
            setCodeLock(false);
        }
    };

    const onFinish = async (values: FieldType) => {
        try {
            if (codeErrorMsg) return;
            setLoading(true);
            const res = await requestMobileCodeLogin({
                mobile: values.mobile,
                code: values.code,
                group: "tinyShopPc"
            });
            if (res.code === 200) {
                setCodeLock(false);
                setCount(0);
                timerId && clearTimeout(timerId);
                setTimerId(null);
                message.success("登录成功");
                setUserInfo(res.data);
                const redirect = searchParams.get("redirect") || "/";
                router.replace(redirect);
            } else if (res.message === CodeError) {
                setCodeErrorMsg(res.message);
            } else {
                message.success(res.message);
            }
        } catch (e) {
            message.success("登录失败");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // 当 count 大于 0 时，开始倒计时
        if (count > 0) {
            setButtonText(`${count}秒后重新获取`);
            const id = setTimeout(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
            setTimerId(id);
        } else {
            // 当 count 为 0 时，清除定时器并更新按钮文本
            if (timerId) clearTimeout(timerId);
            setButtonText("获取验证码");
        }
        return () => {
            if (timerId) clearTimeout(timerId);
        };
    }, [count]);

    useEffect(() => {
        form.setFieldsValue({slideVerification: sliderValidate});
    }, [sliderValidate]);

    return <div>
        <p className="text-black font-bold my-4">手机号登录</p>
        <Form
            form={form}
            name="phone-number-login"
            wrapperCol={{span: 24}}
            style={{maxWidth: 600}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<FieldType>
                name="mobile"
                rules={[{
                    validator: (rule, value) => {
                        const reg = /^[1][3456789][0-9]{9}$/;
                        if (!value) {
                            return Promise.reject("手机号码不能为空");
                        }
                        if (!reg.test(value)) {
                            return Promise.reject("手机号码格式错误");
                        } else {
                            return Promise.resolve();
                        }
                    }
                }]}
            >
                <Input className="!py-2 !text-[16px]" placeholder={"请输入手机号码"}/>
            </Form.Item>
            <Form.Item<FieldType>
                name="code"
                help={codeErrorMsg ? <p className="ant-form-item-explain-error">{codeErrorMsg}</p> : undefined}
                rules={[{
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject("请输入验证码");
                        }
                        if (value.length !== 6) {
                            return Promise.reject("验证码长度为6位");
                        }
                        setCodeErrorMsg("");
                        return Promise.resolve();
                    }
                }]}
            >
                <Input className="!py-2 !text-[16px]"
                       onInput={() => {
                           if (codeErrorMsg) {
                               setCodeErrorMsg("");
                           }
                       }}
                       suffix={
                           <Button type="link" size="small" block onClick={handleGetCode}
                                   disabled={count > 0 || codeLock}>{buttonText}</Button>
                       }
                       placeholder={"请输入短信验证码"}/>
            </Form.Item>
            <Form.Item className="!mb-0 !h-[70px]">
                <SliderVerification getResult={setSliderValidate}/>
                <Form.Item<FieldType>
                    className="relative -top-8 !mb-0"
                    name="slideVerification"
                    valuePropName="checked"
                    rules={[{
                        validator: (rule, value) => {
                            if (!value) return Promise.reject("请完成人机验证");
                            else return Promise.resolve();
                        }
                    }]}
                >
                    <Checkbox/>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <div className="b-flex">
                    <Checkbox onChange={(e) => setRemember(e.target.checked)} checked={remember}>下次自动登录</Checkbox>
                    <ThemeLink href={getForgotPasswordLink()}>
                        忘记密码
                    </ThemeLink>
                </div>
            </Form.Item>
            <Form.Item>
                <Button type="primary" size="large" block htmlType="submit" loading={loading}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>;
}
