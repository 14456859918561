"use client";
import {useState} from "react";
import PhoneNumberLogin from "@/components/login-register/login/phone-number-login";
import WechatLogin from "@/components/login-register/login/wechat-login";
import AccountLogin from "@/components/login-register/login/account-login";
import AgreeToPolicy from "@/components/login-register/agree-to-policy";
import LoginMethod from "@/components/login-register/login-method";
import {LoginMode} from "@/lib/type/login-register.type";

const loginMode: LoginMode[] = [{
    id: 1,
    name: "验证码登录",
    icon: "ri-smartphone-line text-[#1991fa]"
}, {
    id: 2,
    name: "微信登录",
    icon: "ri-wechat-fill text-[#28c445]"
}, {
    id: 3,
    name: "账号登录",
    icon: "ri-lock-unlock-fill text-main"
}];

export default function Login() {
    const [loginModeId, setLoginModeId] = useState<number>(loginMode[0].id);
    return <>
        {
            loginModeId === loginMode[0].id && <PhoneNumberLogin/>
        }
        {
            loginModeId === loginMode[1].id && <WechatLogin/>
        }
        {
            loginModeId === loginMode[2].id && <AccountLogin/>
        }
        <LoginMethod title="其他登录方式" mode={loginMode} activeId={loginModeId} setActiveId={setLoginModeId}/>
        <AgreeToPolicy/>
    </>;
}
