"use client";
import SliderCaptcha, {ActionType} from "rc-slider-captcha";
import {useEffect, useRef, useState} from "react";
import {Spin} from "antd";

type Props = {
    getResult: (result: boolean) => void
}
export default function SliderVerification(props: Props) {
    const actionRef = useRef<ActionType>();
    const sliderVerifRef = useRef<HTMLDivElement>(null);
    const [bgWidth, setBgWidth] = useState<number>(0);
    useEffect(() => {
        if (sliderVerifRef.current) {
            setBgWidth(sliderVerifRef.current.offsetWidth);
        }
    }, [sliderVerifRef.current]);
    return (
        <div className="w-full" ref={sliderVerifRef}>
            {
                !!bgWidth ? <SliderCaptcha
                    className="!w-full relative z-[1]"
                    mode="slider"
                    tipText={{
                        default: "请按住滑块，拖动到最右边",
                        moving: "请按住滑块，拖动到最右边",
                        error: "验证失败，请重新操作",
                        success: "验证成功"
                    }}
                    errorHoldDuration={1000}
                    bgSize={{width: bgWidth || 320, height: 160}}
                    onVerify={(data) => {
                        // 默认背景图宽度 320 减去默认拼图宽度 60 所以滑轨宽度是 260
                        const flag = data.x === bgWidth - 60;
                        props.getResult(flag);
                        if (flag) {
                            return Promise.resolve();
                        }
                        return Promise.reject();
                    }}
                    actionRef={actionRef}
                /> : <div className="w-full c-flex bg-white relative z-[1] -top-1">
                    <Spin/>
                </div>
            }
        </div>
    );
};
