import ThemeLink from "@/components/link";
import {getPrivacyPolicyLink, getRegisterProtocolLink} from "@/lib/util/get-router";

export default function AgreeToPolicy() {
    return <p className="text-themeP text-xs mt-2">未注册手机验证后自动登录，注册即代表同意 <ThemeLink
        href={getRegisterProtocolLink()} className="!text-main hover:underline">《注册协议》</ThemeLink> <ThemeLink
        href={getPrivacyPolicyLink()}
        className="!text-main hover:underline">《隐私条款》</ThemeLink>
    </p>;
}
