import {Placeholder} from "@/components/placeholder";

export default function WechatLogin() {
    return <>
        <p className="text-black font-bold my-4">微信登录</p>
        <div className="c-flex">
            <Placeholder src="/site/app-xiazai.png" imageWidth={300} imageHeight={300}></Placeholder>
        </div>
    </>;
}
