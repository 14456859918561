'use client';
import { Card } from 'antd';
import Login from '@/components/login-register/login';
import { usePathname } from 'next/navigation';
import ThemeLink from '@/components/link';
import { getLoginLink, getRegisterLink } from '@/lib/util/get-router';
import Register from '@/components/login-register/register';

export default function LoginRegister() {
  const pathname = usePathname();
  return (
    <Card className="shadow-lg relative !border-mainBg overflow-hidden">
      <div className="absolute right-0 top-0 cursor-pointer">
        <div className="w-0 h-0 border-t-[70px] border-l-[70px] border-t-main border-l-transparent"></div>
        <ThemeLink
          href={pathname === '/login' ? getRegisterLink() : getLoginLink()}
          className="text-white absolute z-[1] hover:text-white right-1 top-2 text-[16px]"
        >
          {pathname === '/login' ? '注册' : '登录'}
        </ThemeLink>
      </div>
      <div className="mt-8">
        {pathname === '/login' ? <Login /> : <Register />}
      </div>
    </Card>
  );
}
